import React from "react";
import heroVideo from "../videos/heroVideo.mp4";
import './Hero.css'

function Hero() {
  return (
    <div className="heroVideo">
      <video
      width='100%'
      height='100%'
      autoPlay
      muted
      loop="true"
      src='https://videos.pexels.com/video-files/1321208/1321208-uhd_3840_2160_30fps.mp4' />
    </div>
  );
}

export default Hero;
