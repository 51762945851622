import React from 'react'
import './HeroText.css'

function HeroText(props) {
  return (
    <div className='container heroTextContainer'>
        <div className='left'>
            <h1>{props.title}</h1>
            <p>{props.paragraph}</p>
            <p>{props.paragraph2}</p>
            </div>
        <div className='right'>
            <h2>Capabilities</h2>
            <ul>
                <li>Capability 1</li>
                <li>Capability 2</li>
                <li>Capability 3</li>
            </ul>
        </div>
    </div>
  )
}

export default HeroText