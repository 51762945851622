import React, { useState } from "react";
import MainNav from "../Components/Nav";
import Hero from "../Components/Hero";

const AuthProtection = ({ username, password }) => {
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    if (inputUsername === "username" && inputPassword === "password") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      alert('Incorrect details, please try again')
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <MainNav />
          <Hero />
        </div>
      ) : (
        <div className="loginContainer">
          <h1>Please log in</h1>
          <input
            type="text"
            placeholder="Username"
            value={inputUsername}
            onChange={(e) => setInputUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default AuthProtection;
