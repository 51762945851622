import React from 'react'
import './SingleImage.css'

function SingleImage() {
  return (
    <>
    <div className='SingleImageContainer container bottom'>
        <img src="https://cdn.britannica.com/73/114973-050-2DC46083/Midtown-Manhattan-Empire-State-Building-New-York.jpg" alt="" />
    </div>
    </>
  )
}

export default SingleImage