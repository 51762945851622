import "../App.css";
import "../Components/MediaLeftTextRight.css";
import MediaLeftTextRight from "../Components/MediaLeftTextRight";
import MainNav from "../Components/Nav";
import Hero from "../Components/Hero";
import HeroText from "../Components/HeroText";
import ImageGroup from "../Components/ImageGroup";
import ImageTextVertical from "../Components/ImageTextVertical";
import TwoImages from "../Components/TwoImages";
import SingleImage from "../Components/SingleImage";

function TemplatePage() {
  return (
    <div className="roboto-regular">
      <MainNav />
      <Hero />
      <HeroText
        title="H1 Title"
        paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        paragraph2="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <ImageGroup />
      <ImageTextVertical />
      <TwoImages />
      <MediaLeftTextRight />
      <SingleImage />
    </div>
  );
}

export default TemplatePage;
